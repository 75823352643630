/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import React, { useEffect } from 'react'
import { Input } from '../../../components/Input'
import { PrimaryButton } from '../../../components/Button'
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom'
import routes from '../../../routes'
import useStores from '../../../hooks/useStores'
import { ITokenPair } from '../../../api'
import { FormErrors, qrCodeLocalStorageKey } from '../../../constants'
import { validateEmail, AuthCustomError } from '../utils'
import { AxiosError } from 'axios'
import { AuthError } from '../../../api/apiErrors'
import { toast } from 'react-toastify'
import { Notification } from '../../../components/Notifications'

const RestoreLinkWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
`

export const AdditionalText = styled(NavLink)`
  font-weight: 400;
  font-size: 15px;
  color: white;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  text-align: right;
`

const handleLoginError = (e: AxiosError) => {
  console.error('Sign in error', e.message)
  let message = ''
  if (e instanceof AuthCustomError) {
    message = e.message
  } else if (e.response) {
    const { errors = [] } =  e.response.data as any
    if (errors.includes(AuthError.UserNotFound) || errors.includes(AuthError.WrongPassword)) {
      message = 'Неверный логин или пароль'
    } else if (errors.includes(AuthError.UserShouldBeConfirmed)) {
      message = 'Аккаунт не подтвержден'
    }
  }

  toast.dismiss()
  toast(<Notification isError message={message} />, { hideProgressBar: true, autoClose: 5000 })
}

export const SignIn = () => {
  const { authStore, api, keysStore } = useStores()
  const { register, handleSubmit, reset, formState: { errors, isValid }, watch } = useForm({ mode: 'onSubmit' })
  const navigate = useNavigate()
  const { code } = useParams()
  const query = new URLSearchParams(useLocation().search)
  const uuid = query.get('token')

  const signIn = async (tokens: ITokenPair) => {
    const {
      axios: refresherAxios,
    } = authStore.createRefresherAxios(tokens as ITokenPair, () => {
      authStore.logout()
      toast(<Notification message='В целях безопасности, нужно перезайти в аккаунт, чтобы получить это NFT' />)
      navigate(`/${routes.signIn}`)
    })
    api.setupApi(refresherAxios)
    authStore.setLoggedIn(true)
    await authStore.loadUserProfile()
    keysStore.init()
    toast.dismiss()
    reset()
    const code = localStorage.getItem(qrCodeLocalStorageKey)
    if (code) {
      navigate(`/${routes.qrCode}`)
    } else {
      navigate('/')
    }
  }

  const confirmUserAndSignIn = async () => {
    const tokens = await api.confirmUser(uuid!)
    await signIn(tokens)
  }

  useEffect(() => {
    if (uuid) {
      confirmUserAndSignIn()
    }
    if (code) {
      localStorage.setItem(qrCodeLocalStorageKey, code)
    }
  }, [])

  const onSubmit = async (data: any) => {
    const { email, password } = data
    try {
      const tokens = await authStore.signIn(email, password)
      await signIn(tokens)
    } catch (e: any) {
      handleLoginError(e)
    }
  }

  return <form onSubmit={handleSubmit(onSubmit)}>
    <Input
      placeholder='Электронная почта'
      {...register('email', {
        required: FormErrors.EnterEmail,
        validate: validateEmail,
        setValueAs: v => v.trim(),
      })}
      error={errors.email && (errors.email.message || (errors.email.type === 'validate' && FormErrors.EmailIsIncorrect))}
    />
    <Input
      placeholder='Пароль'
      {...register('password', {
        required: FormErrors.EnterPassword,
        setValueAs: v => v.trim(),
      })}
      type='password'
      isDirty={!!watch('password')}
      error={errors.password && errors.password.message}
    />
    <RestoreLinkWrapper>
      <AdditionalText to={`/${routes.recover}`}>Восстановить пароль</AdditionalText>
    </RestoreLinkWrapper>
    <PrimaryButton isWide type='submit'>Войти</PrimaryButton>
  </form>
}
